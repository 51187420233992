import React from 'react'
import NewTemplate from '../Components/NewTemplate/NewTemplate'
import { Helmet } from 'react-helmet'

export default function newTemplate() {
    return (
        <div>
             <Helmet>
                <meta charSet="utf-8" />
                <title>Prodocs | Create Template</title>
                <link rel="canonical" href="/prodocs-logo.png" />
            </Helmet>
            <NewTemplate/>
        </div>
    )
}
